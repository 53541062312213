import { GUEST_CAPACITY_FACET_ID } from '@components/vendorCard/constants';
import { Conversations } from '@typings/messagedVendors';
import { State } from '@typings/redux';
import { createSelector } from 'reselect';
import { findFacet } from '../../../../../../../decorators/vendor/helpers';

export const filterAlreadyMessagedVendors = (
	messagedVendors: MessagedVendors.Conversations,
	similarVendors: Vendor.Similar[],
) => {
	const excludedStorefrontIds = new Set();
	for (const id in messagedVendors) {
		excludedStorefrontIds.add(id);
	}

	return similarVendors.filter((r) => !excludedStorefrontIds.has(r.id));
};

export const MAX_VRM_VENDORS_DISPLAYED = 6;
export const VRM_VENDORS_REQUESTED = 6;

const inlineGuestCountSelector = (state: State) =>
	state.rfq.inline?.fields?.guestCount?.value;
const rfqFormMinGuestCountSelector = (state: State) =>
	state.rfq.rfqFormData?.guest_count?.value?.minGuestCount.toString();
const rfqFormMaxGuestCountSelector = (state: State) =>
	state.rfq.rfqFormData?.guest_count?.value?.maxGuestCount.toString();

export const selectUserMaxGuestCount = createSelector(
	[
		inlineGuestCountSelector,
		rfqFormMinGuestCountSelector,
		rfqFormMaxGuestCountSelector,
	],
	(inlineGuestCount, rfqFormMinGuestCount, rfqFormMaxGuestCount) => {
		return inlineGuestCount
			? inlineGuestCount
			: // RFQ Modal - maxGuestCount is 0 when the user selects "301 or more"
			  rfqFormMaxGuestCount === '0'
			  ? rfqFormMinGuestCount // minGuestCount is 301
			  : rfqFormMaxGuestCount;
	},
);

export const getMaxGuestCountFromRangeText = (guestCountRange: string) =>
	Number(guestCountRange.match(/\d+(?=$)/)?.[0]) || // for formats like "251-300"
	Number(guestCountRange.match(/\d+1(?=\D+$)/)?.[0]) || // for formats like "301+" and "301 or more"
	Number(guestCountRange.match(/\d+0(?=\D+$)/)?.[0]) + 1 || // for formats like "300+" and "300 or more"
	0;

export const compareGuestCapacity = (
	userGuestCount: string,
	similarVendorCapacity: string,
): boolean =>
	getMaxGuestCountFromRangeText(userGuestCount) <=
	getMaxGuestCountFromRangeText(similarVendorCapacity);

export const filterProfilesByFacet = (
	facetId: string,
	filterFn: (valueA: any, valueB: any) => boolean,
	similarVendors: Vendor.Similar[],
	usersValue: string,
	fillWithVendorsWithoutThatFacet = true,
): Vendor.Similar[] => {
	const vendorsFilteredByFacet = similarVendors.filter(
		(similarVendor) =>
			findFacet(similarVendor, facetId) &&
			filterFn(usersValue, findFacet(similarVendor, facetId)),
	);
	const vendorsWithoutThatFacet = similarVendors.filter(
		(similarVendor) => !findFacet(similarVendor, facetId),
	);

	return [
		...vendorsFilteredByFacet,
		...(fillWithVendorsWithoutThatFacet ? vendorsWithoutThatFacet : []),
	].slice(0, MAX_VRM_VENDORS_DISPLAYED);
};

export const filterVendorsByGuestCapacity = (
	similarVendors: Vendor.Similar[],
	userMaxGuestCount: string,
) => {
	return filterProfilesByFacet(
		GUEST_CAPACITY_FACET_ID,
		compareGuestCapacity,
		similarVendors,
		userMaxGuestCount,
	);
};

export const filterVendors = (
	messagedVendors: Conversations,
	similarVendors: Vendor.Similar[],
	userMaxGuestCount?: string,
) => {
	let filteredVendors = filterAlreadyMessagedVendors(
		messagedVendors,
		similarVendors,
	);

	if (!!userMaxGuestCount) {
		filteredVendors = filterVendorsByGuestCapacity(
			filteredVendors,
			userMaxGuestCount,
		);
	}

	return filteredVendors;
};
