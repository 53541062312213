import Honeybadger from '@honeybadger-io/js';
import { experimentAPIKey } from '@settings';
import { isObject } from '@utils/isObject';
import FlipperClient from '@xo-union/sdk-flipper';

const client = new FlipperClient({
	apiKey: experimentAPIKey,
});

// Retry five times before reporting to Honeybadger.
const retryIfNecessary = async (
	anonymousId: string,
	experimentId: string,
	variantId: string,
	retries: number,
) => {
	const nextRetryCount = retries + 1;
	if (retries < 5) {
		await reportAssignment(
			anonymousId,
			experimentId,
			variantId,
			nextRetryCount,
		);
	}
};

// Caught errors have type any
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const notifyHoneybadgerIfNecessary = (
	context: Record<string, unknown>,
	errorOrResponse: any,
	retries: number,
) => {
	if (retries < 5) {
		return;
	}

	// Ensure the errorOrResponse data is an object before checking for object keys 'body' and 'status'
	const isResponse =
		isObject(errorOrResponse) &&
		'body' in errorOrResponse &&
		'status' in errorOrResponse;
	const error = isResponse
		? 'Error reporting assignment to Flipper'
		: errorOrResponse;
	const name = 'Error reporting assignment to Flipper';
	const fingerprint = 'assignment report error';

	if (isResponse) {
		context.responseBody = errorOrResponse.body;
		context.responseStatus = errorOrResponse.status;
	}

	Honeybadger.notify(error, {
		name,
		context,
		fingerprint,
	});
};
interface LogMissingIdsParams {
	response: Response;
	context: {
		anonymousId: string;
		experimentId: string;
		variantId: string;
	};
}
function logIfMissingIds({ response, context }: LogMissingIdsParams) {
	const { anonymousId, experimentId, variantId } = context;
	if (anonymousId && experimentId && variantId) return;
	const honeybadgerDescription =
		'Missing Ids when reporting assignment to Flipper';
	Honeybadger.notify(honeybadgerDescription, {
		name: honeybadgerDescription,
		context: {
			...context,
			url: response.url,
			response: response.body,
			status: response.status,
		},
		fingerprint: 'assignment report error',
	});
}

export const reportAssignment = async (
	anonymousId: string,
	experimentId: string,
	variantId: string,
	retries = 0,
) => {
	const context = { anonymousId, experimentId, variantId };

	try {
		const { response } = await client.reportAssignment({
			bucketingId: anonymousId,
			experimentId,
			variantId,
		});
		// Response might be bad and still not throw an exception.
		// We can check this with response.ok.
		if (response.ok) {
			logIfMissingIds({ response, context });
			return;
		}

		await retryIfNecessary(anonymousId, experimentId, variantId, retries);
		notifyHoneybadgerIfNecessary(context, response, retries);
	} catch (err) {
		await retryIfNecessary(anonymousId, experimentId, variantId, retries);
		notifyHoneybadgerIfNecessary(context, err, retries);
	}
};
