import { ClassesMapper } from '@xo-union/react-css-modules';
import {
	Option,
	Select,
	SelectChangeEvent,
} from '@xo-union/tk-component-fields';
import React, { VFC } from 'react';
import { unifyGuestNumberFormat } from '../../../../../../../../../lib/xo-rfq-forms/data/loader/utils/general';
import { useInlineRfqForm } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { getGuestCountOptions, handleFieldChange } from '../../../../utils';
import { getFieldLabel } from '../helpers';

export interface GuestCountSelectProps {
	selectStyle: ClassesMapper<string>;
	context: ReturnType<typeof useInlineRfqForm>;
	isRequired?: boolean;
}

export const GuestCountSelect: VFC<GuestCountSelectProps> = (props) => {
	const { selectStyle, context, isRequired = false } = props;
	const { setFieldValue, shouldShowErrors } = context;
	const { guestCount: contextValue } = context.values;
	const GUESTS = getGuestCountOptions();

	const handleGuestCountChange = (e: SelectChangeEvent) => {
		const { value } = e.target;

		handleFieldChange({
			cb: setFieldValue,
			data: contextValue,
			name: 'guestCount',
			value,
		});
	};

	return (
		<Select
			classes={selectStyle}
			label={getFieldLabel(contextValue.label, isRequired)}
			name="guestCount"
			onChange={handleGuestCountChange}
			state={shouldShowErrors ? contextValue.state : 'neutral'}
			subText={shouldShowErrors ? contextValue.error : ''}
			value={unifyGuestNumberFormat(contextValue.value)}
		>
			{GUESTS.map((guest) => (
				<Option key={guest} label={guest} value={guest} />
			))}
		</Select>
	);
};

export default GuestCountSelect;
