import RfqLegalCopy from '@components/RfqLegalCopy/RfqLegalCopy';
import classNames from 'classnames';
import React, { VFC } from 'react';
import { connect } from 'react-redux';
import { checkIsTablet, resolveButtonText } from '../../utils';
import Styles from './styles.scss';

export interface TermsOfUseProps {
	catCode: Category.CategoryCode;
	viewport: Redux.Viewport;
}

export const TermsOfUse: VFC<TermsOfUseProps> = (props) => {
	const { catCode, viewport } = props;
	const { isMobile } = viewport;
	const isTablet = checkIsTablet(viewport);
	const btnText = resolveButtonText({
		catCode,
		isMobile,
		isTablet,
	});

	return (
		<div
			className={classNames({
				[Styles.mobileTerms]: isMobile,
				[Styles.terms]: !isMobile,
			})}
		>
			<RfqLegalCopy btnText={btnText} />
		</div>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	catCode: state.category.code,
	viewport: state.viewport,
});

export default connect(mapStateToProps)(TermsOfUse);
