import { transformVendorTier } from '@utils/transformVendorTier';
import NavigationHelper from '../../../helpers/navigation';

function getVendorTransactions(vendor: Vendor.Decorated) {
	return vendor.addOns && !!vendor.addOns.find((a) => a.code === 'TX');
}

const formatVendorUrl = (
	websiteUrl: string | null,
	displayWebsiteUrl: string | null,
) => {
	const LEADING_HTTP_REGEX = /^http(s)*:\/\//i;
	const url = websiteUrl ?? displayWebsiteUrl;

	if (url) {
		return LEADING_HTTP_REGEX.test(url) ? url : `http://${url}`;
	}

	return null;
};

const formatVendorBOW = (awards: string[] = []) => {
	if (awards && awards.length) {
		const removeBOWFromValues = awards.map((award) => award.split('BOW')[1]);
		const uniqArray = new Set(removeBOWFromValues);
		return Array.from(uniqArray).join(', ');
	}

	return null;
};

/*
 * Segment strips out properties with values undefined.
 * For instance: not every storefront has BestOfWeddings or ReferredMarketCode.
 *
 * Set null to all vendor properties when they are undefined, so it provides predictable and consistent properties values.
 */
const formatVendorInitiatedProps = (properties: VendorInitiatedProps) => {
	const copy: Partial<VendorInitiatedProps> = {};
	for (const prop in properties) {
		if (properties[prop] === undefined || properties[prop] === '') {
			copy[prop] = null;
		} else {
			copy[prop] = properties[prop];
		}
	}

	return copy;
};

interface VendorInitiatedProps {
	[key: string]: string | boolean | number | undefined | null;
	additionalVendors: boolean;
	leadFormType: string;
	mediaSummaryCount: number | undefined | null;
	partnerAccountId: string | undefined | null;
	partnerAccountStatus: string | undefined | null;
	partnerVendorId: string | undefined | null;
	phone: boolean;
	sourcePage: string | undefined | null;
	vendorAddress: string | null;
	vendorCategoryCode: string | undefined | null;
	vendorCity: string | undefined | null;
	vendorClaimedStatus: string | undefined | null;
	vendorDisplayId: string | undefined | null;
	vendorFomattedPhoneNumber: string | undefined | null;
	vendorId: string | undefined | null;
	vendorMarketCode: string | undefined | null;
	vendorName: string | undefined | null;
	vendorPriceTier: string | undefined | null;
	vendorPurchaseStatus: string | undefined | null;
	vendorReferredMarketCode: string | undefined | null;
	vendorState: string | undefined | null;
	vendorVendorTier: string | undefined | null;
	vendorZip: string | undefined | null;
}

const vendorInitiatedBaseProps = (
	vendor: Vendor.Decorated,
	pageType: string,
): VendorInitiatedProps => {
	const {
		accountId,
		accountStatus,
		address,
		categoryCode,
		claimedStatus,
		displayId,
		id,
		marketCode,
		mediaSummary,
		name,
		phone,
		priceRange,
		purchaseStatus,
		vendorId,
		vendorTier,
	} = vendor;
	const vendorPaid = purchaseStatus === 'PAID' ? 'paid' : 'unpaid';
	const sourcePage =
		pageType === 'storefront' ? `${vendorPaid} storefront` : 'category results';

	return {
		additionalVendors: false,
		leadFormType: 'direct',
		mediaSummaryCount: mediaSummary?.total,
		partnerAccountId: accountId,
		partnerAccountStatus: accountStatus,
		partnerVendorId: vendorId,
		phone: !!phone,
		sourcePage,
		vendorAddress: address?.address ?? null,
		vendorCategoryCode: categoryCode,
		vendorCity: address?.city,
		vendorClaimedStatus: claimedStatus,
		vendorDisplayId: displayId,
		vendorFomattedPhoneNumber: phone,
		vendorId: id,
		vendorMarketCode: marketCode,
		vendorName: name,
		vendorPriceTier: priceRange,
		vendorPurchaseStatus: purchaseStatus,
		vendorReferredMarketCode:
			NavigationHelper.sanitizedVendorReferredMarketCode(marketCode),
		vendorState: address?.state,
		vendorVendorTier: transformVendorTier(vendorTier),
		vendorZip: address?.postalCode,
	};
};

export {
	formatVendorBOW,
	formatVendorInitiatedProps,
	formatVendorUrl,
	vendorInitiatedBaseProps,
	getVendorTransactions,
};
