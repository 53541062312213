import { useAppSelector } from '@redux/hooks';
import { Checkbox } from '@xo-union/tk-component-switches';
import { Body1 } from '@xo-union/tk-ui-typography';
import React, { VFC } from 'react';
import { useInlineRfqForm } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import Styles from './DateFlexible.scss';

export interface DateFlexibleProps {
	context: ReturnType<typeof useInlineRfqForm>;
}

export const DateFlexible: VFC<DateFlexibleProps> = (props) => {
	const { context } = props;
	const { setFieldValue, values } = context;
	const { isDateFlexible } = values;

	const updateIsDateFlexible = () => {
		setFieldValue(!isDateFlexible, 'isDateFlexible');
	};

	return (
		<div className={Styles.checkbox}>
			<Checkbox checked={isDateFlexible} onChange={updateIsDateFlexible} />
			<Body1>My wedding date is flexible</Body1>
		</div>
	);
};

export default DateFlexible;
