import ConfirmationAndPasswordModal from '@components/auto-account-creation/ConfirmationAndPasswordModal/ConfirmationAndPasswordModal';
import VRMConfirmationModalV2 from '@components/auto-account-creation/VRMConfirmationModalV2/VRMConfirmationModalV2';
import PasswordModalV2, {
	parseEmail,
} from '@components/auto-account-creation/passwordModalV2/PasswordModalV2';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import * as AlertActions from '@redux/alert/actionCreators';
import * as ExperimentActions from '@redux/experiments/actions/thunks';
import * as RfqActions from '@redux/rfq/actionCreators';
import { changePassword as changePasswordAction } from '@redux/rfq/thunks';
import { fetchTemporaryPassword } from '@redux/rfq/utils';
import {
	buildMultipleLeadSuccessCopy,
	determineReferredMarketCode,
} from '@utils/index';
import { isFromSearchPage } from '@utils/regex';
import Spinner from '@xo-union/tk-component-spinner';
import { useDesktopMedia } from '@xo-union/tk-ui-breakpoints';
import React, { lazy, ReactNode, Suspense, useState, VFC } from 'react';
import { connect } from 'react-redux';
import AnalyticsEvents from '../../../../../../../../../constants/analytics';
import { VENDOR_REFERRED_MARKET_CODE } from '../../../../../../../../../constants/cookies';
import {
	getVisitId,
	getVisitorId,
} from '../../../../../../../../../utils/cookies';
import { cookieGetItem } from '../../../../../../../../utils/cookie/consentManagementCookie';

const { SAVE_PASSWORD, PASSWORD_CREATE } = AnalyticsEvents;
const VRM = lazy(() => import(/* webpackChunkName: "vrm" */ '../VRM'));

interface AnalyticsArgs {
	action: string;
	sourceContent: string;
}

interface StateProps {
	anonymousId: string;
	buttonType: string;
	changePasswordStatus: Rfq.ChangePasswordStatus;
	creationSuccessful: string;
	email: string;
	isReferred: boolean;
	isUserRegistered: boolean;
	marketCode: string;
	member: Membership.Member | null;
	pageType: string;
	referrer: string;
	storefront: Vendor.Decorated;
	userId?: string;
}

interface DispatchProps {
	changePassword: (newPassword: string, type?: string) => void;
	setContentAndStatus: (
		content: ReactNode,
		status: boolean,
		link?: Alert.Link,
	) => void;
	setJourneyStatus: (
		userJourneyStatus: 'init' | 'exit-early' | 'complete',
	) => void;
}

interface OwnProps {
	initiator: string | null;
	modal: string | null;
	recommendedVendors: API.FESharedProfile[];
	rfqType?: string;
	setModal: (modal: string | null) => void;
	toggleVRM: (status: boolean) => void;
	vendorRaw: Vendor.Raw;
	reportServerSideExperiment: (experiment: string) => void;
}

enum Steps {
	Vrm = 'vrm',
	VrmConfirm = 'vrm-confirm',
	PasswordModal = 'password-modal',
}

export type VrmModalsProps = OwnProps & StateProps & DispatchProps;

const VrmModals: VFC<VrmModalsProps> = (props) => {
	const {
		anonymousId,
		buttonType,
		changePassword,
		changePasswordStatus,
		creationSuccessful,
		email,
		initiator,
		isReferred,
		isUserRegistered,
		marketCode,
		member,
		modal,
		pageType,
		recommendedVendors,
		referrer,
		setContentAndStatus,
		setJourneyStatus,
		setModal,
		storefront,
		toggleVRM,
		userId,
		vendorRaw,
	} = props;
	const { track } = useAnalyticsContext();
	const [contactedVendors, setContactedVendors] = useState(
		[] as API.SendBulkInquirySuccess[],
	);
	const [contactedVendorsCount, setContactedVendorsCount] = useState(1);
	const [indirectLeads, setIndirectLeads] = useState(0);
	const [password, setPassword] = useState('');
	const media = useDesktopMedia();
	const visitId = getVisitId();
	const visitorId = getVisitorId();

	const isComingFromStorefront = pageType === 'storefront';

	const setModalNextStep = (
		prevStep?: Steps,
		contactedVendorsCount?: number,
	) => {
		if (contactedVendorsCount) {
			if (fetchTemporaryPassword()) {
				setModal(Steps.PasswordModal);
				return;
			}

			if (prevStep === Steps.Vrm) {
				setModal(Steps.VrmConfirm);
				return;
			}
		}

		if (!fetchTemporaryPassword()) {
			const alertContent = buildMultipleLeadSuccessCopy(contactedVendorsCount);
			setContentAndStatus(alertContent, true);
			handleEarlyExit({
				sourceContent: 'vrm',
				action: 'exit-early',
			});
			return;
		}

		handleEarlyExit({
			sourceContent: 'vrm',
			action: 'exit-early',
		});
	};

	const populateContactedVendorsProperties = (
		contactedVendor: API.SendBulkInquirySuccess,
	) => ({
		...contactedVendor,
		...recommendedVendors.find((r) => r.id === contactedVendor.storefrontId),
	});

	const onVRMSubmit = (contactedVendors: API.SendBulkInquirySuccess[]) => {
		const contactedVendorsCount = contactedVendors.length + 1;
		const populatedContactedVendors = contactedVendors.map(
			populateContactedVendorsProperties,
		);

		setContactedVendors(populatedContactedVendors);
		setContactedVendorsCount(contactedVendorsCount);
		setIndirectLeads(contactedVendors.length);
		setModalNextStep(Steps.Vrm, contactedVendorsCount);
	};

	const handleEarlyExit = (analytics?: AnalyticsArgs) => {
		if (analytics) {
			const { sourceContent, action } = analytics;
			handleAnalytics(sourceContent, action);
		}
		if (isNewUser && modal !== Steps.PasswordModal) {
			setModal(Steps.PasswordModal);
			setJourneyStatus('exit-early');
			return;
		}
		setModal(null);
		toggleVRM(false);
		setJourneyStatus('exit-early');
	};

	const handlePasswordSubmit = (analytics: AnalyticsArgs) => {
		const { sourceContent, action } = analytics;
		changePassword(email, password);
		handleAnalytics(sourceContent, action);
		setModal(null);
		toggleVRM(false);
	};

	const handleAnalytics = (sourceContent: string, action: string) => {
		const properties = {
			action,
			anonymousId,
			platform: media.yes ? 'desktop web' : 'mobile web',
			sourceContent,
			visitId,
			visitorId,
			userId,
		};

		track({
			event: AnalyticsEvents.POST_LEAD_INTERACTION,
			properties,
		});
	};

	const handleNextButtonClick = (analytics?: AnalyticsArgs) => {
		if (analytics) {
			const { sourceContent, action } = analytics;
			handleAnalytics(sourceContent, action);
		}
		setModalNextStep(Steps.VrmConfirm);
	};

	const isNewUser = !isUserRegistered && creationSuccessful === 'success';
	const wrapOnClose = () => {
		setJourneyStatus('exit-early');
		if (fetchTemporaryPassword()) {
			setModal(Steps.PasswordModal);
		} else {
			toggleVRM(false);
		}
	};

	const isFromSearch = isFromSearchPage(referrer);
	const cookieMarketCode = cookieGetItem(VENDOR_REFERRED_MARKET_CODE);
	const referred = determineReferredMarketCode({
		cookieMarketCode,
		isFromSearch,
		isReferred,
		marketCode,
	});

	return (
		<>
			<Suspense fallback={<Spinner />}>
				<VRM
					buttonType={buttonType}
					initiator={initiator}
					isOpen={modal === 'vrm' && recommendedVendors.length > 0}
					member={member || null}
					onClose={wrapOnClose}
					onSubmit={onVRMSubmit}
					pageType={pageType}
					recommendedVendors={recommendedVendors}
					referred={referred}
					toggleIsVRMOpen={toggleVRM}
					vendorRaw={vendorRaw}
					storefront={storefront}
				/>
				{modal === Steps.VrmConfirm && (
					<VRMConfirmationModalV2
						isModalOpen={modal === Steps.VrmConfirm}
						closeCallback={handleEarlyExit}
						onNextButtonClick={handleNextButtonClick}
						recommendedVendors={recommendedVendors}
						contactedVendors={contactedVendors}
						indirectLeadsCount={indirectLeads}
						sourcePage={pageType}
					/>
				)}
				{modal === Steps.PasswordModal && contactedVendorsCount !== 1 && (
					<ConfirmationAndPasswordModal
						isModalOpen={modal === Steps.PasswordModal}
						closeCallback={handleEarlyExit}
						changePasswordStatus={changePasswordStatus}
						contactedVendors={contactedVendors}
						indirectLeadsCount={indirectLeads}
						handleSubmit={handlePasswordSubmit}
						password={password}
						updatePassword={setPassword}
					/>
				)}
				{modal === Steps.PasswordModal && contactedVendorsCount === 1 && (
					<PasswordModalV2
						isModalOpen={modal === Steps.PasswordModal}
						closeCallback={handleEarlyExit}
						changePasswordStatus={changePasswordStatus}
						handleSubmit={handlePasswordSubmit}
						submitAnalytics={{
							action: SAVE_PASSWORD,
							sourceContent: PASSWORD_CREATE,
						}}
						password={password}
						updatePassword={setPassword}
					/>
				)}
			</Suspense>
		</>
	);
};

const mapStateToProps = (state: Redux.State) => {
	return {
		anonymousId: state.settings.anonymousId,
		buttonType: state.rfq.buttonType,
		changePasswordStatus: state.rfq.changePasswordStatus,
		creationSuccessful: state.rfq.creationSuccessful,
		email: parseEmail(state),
		isReferred: state.settings.isReferred,
		isUserRegistered: state.rfq.isUserRegistered,
		marketCode: state.settings.marketCode,
		member: state.membership.member,
		pageType: state.page.pageType,
		referrer: state.requestInfo.info.referrer,
		storefront: state.vendor.vendor,
		userId: state.membership?.member?.id,
	};
};

const mapDispatchToProps = {
	changePassword: changePasswordAction,
	setContentAndStatus: AlertActions.setContentAndStatus,
	setJourneyStatus: RfqActions.setUserJourneyStatus,
	reportServerSideExperiment: ExperimentActions.reportServerSideExperiment,
};

export default connect(mapStateToProps, mapDispatchToProps)(VrmModals);
