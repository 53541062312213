import NavigableModal from '@components/NavigableModal/NavigableModal';
import { selectAssignment } from '@redux/experiments/selectors';
import { useAppSelector } from '@redux/hooks';
import { compose } from '@xo-union/react-css-modules';
import React, { FC } from 'react';
import { UseInlineRfqFormReturn } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { checkIsTablet } from '../../../../utils';
import Styles from './InlineRfqModalWrapper.scss';

const tabletModalClasses = compose({
	'button-container': Styles.tabletButtonContainer,
	'close-button': Styles.tabletCloseButton,
	large: Styles.tabletModal,
});

const mobileModalClassesExperiment = compose({
	container: Styles.container,
	content: Styles.content,
});

const mobileModalClassesControl = compose({
	container: Styles.container,
	content: Styles.content,
	'modal-overlay': Styles.modalOverlay,
});

export interface ModalWrapperProps {
	context: UseInlineRfqFormReturn;
	viewport: Redux.Viewport;
	onClose: () => void;
	isModalOnModal?: boolean;
}

export const InlineRfqModalWrapper: FC<ModalWrapperProps> = ({
	context,
	children,
	viewport,
	onClose,
	isModalOnModal = false,
}) => {
	const branchBannerAssignment = useAppSelector((state) =>
		selectAssignment(state, ['branchBanner']),
	);
	const isTabletOrMedium = checkIsTablet(viewport);
	const { isMobile } = viewport;
	const isDesktop = !isTabletOrMedium && !isMobile;

	let classes: ReturnType<typeof compose>;
	if (isMobile) {
		classes =
			branchBannerAssignment === 'new-banner'
				? mobileModalClassesExperiment
				: mobileModalClassesControl;
	} else if (isTabletOrMedium) {
		classes = tabletModalClasses;
	} else classes = compose({});

	return (
		<NavigableModal
			hash="rfq-modal"
			isModalOpen={context.isModalOpen}
			closeCallback={onClose}
			classes={classes}
			size={isDesktop ? 'sm' : 'lg'}
			isModalOnModal={isModalOnModal}
		>
			{children}
		</NavigableModal>
	);
};
