import Icon from '@xo-union/tk-component-icons';
import { Body2 } from '@xo-union/tk-ui-typography';
import React from 'react';
import Styles from './styles.scss';

const QuickResponderBadge = () => (
	<div className={Styles.quickResponderBadge}>
		<Icon name="quick_reply" size="sm" />
		<Body2>Typically responds within 24 hours</Body2>
	</div>
);

export default QuickResponderBadge;
