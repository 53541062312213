import { Conversations } from '@typings/messagedVendors';
import { State } from '@typings/redux';
import noop from '@utils/noop';
import { Button, Link } from '@xo-union/tk-component-buttons';
import cx from 'classnames';
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { inboxUrl } from '../../../../../settings';
import Styles from './RfqButton.scss';

const TYPES = {
	MESSAGE: 'message',
	REQUEST: 'request',
};

const ALT_VENDORS = [
	// these will have 'Schedule Appointment'
	'BEA',
	'JWL',
	'WJW',
	'ALP',
	'BWP',
	'MEN',
];

const ALT_TEXT = 'Schedule Appointment';

type StateProps = {
	vendor: Vendor.Decorated;
	vendorRaw: Vendor.Raw;
	messagedVendors: Conversations;
};

type OwnProps = {
	handleCTAClick: () => void;
	color?: Union.Color; // Union colors; defaults to primary
	size?: Union.Size; // Union sizes; defaults to md
	label?: string; // button text, the default is 'Request Quote'
	styleName?: string; // pass in custom css-module style names to override button styles
	type?: string; // 'message' will open the message vendor form and 'request' will open the request for quote form
	wrapStyle?: string; // the default wrapper styles around the button
	htmlId?: string; // optional id for the button
};

type RfqButtonProps = StateProps & OwnProps;

const getCategoryCode = (vendorRaw: RfqButtonProps['vendorRaw']) => {
	return vendorRaw.categories[0].code;
};

const isREC = (vendorRaw: RfqButtonProps['vendorRaw']) => {
	return getCategoryCode(vendorRaw) === 'REC';
};

const isPaidNonREC = (
	vendor: RfqButtonProps['vendor'],
	vendorRaw: RfqButtonProps['vendorRaw'],
) => {
	return getCategoryCode(vendorRaw) !== 'REC' && vendor.isPaid;
};

const determineButtonStyle = (
	vendor: RfqButtonProps['vendor'],
	vendorRaw: RfqButtonProps['vendorRaw'],
	wrapStyle: RfqButtonProps['wrapStyle'],
) => {
	if (isREC(vendorRaw) || isPaidNonREC(vendor, vendorRaw)) {
		return wrapStyle;
	}
	return Styles.twoButtons;
};

const getCategoryLabel = (
	vendorRaw: RfqButtonProps['vendorRaw'],
	type: RfqButtonProps['type'],
	label: RfqButtonProps['label'],
) => {
	const { code: category } = vendorRaw.categories[0] || null;
	if (category && type === TYPES.REQUEST) {
		if (ALT_VENDORS.find((elem) => elem === category)) {
			return ALT_TEXT;
		}
	}
	return label;
};

const vendorHasPrimaryEmail = (vendorRaw: RfqButtonProps['vendorRaw']) => {
	if (!vendorRaw) {
		return false;
	}
	const email = vendorRaw.emails.find(
		(singleEmail) => singleEmail.type === 'PRIMARY',
	);
	return email ? 'address' in email : '';
};

export const RfqButton = memo((props: RfqButtonProps) => {
	const {
		handleCTAClick = noop,
		color = 'primary',
		label = 'Request Quote',
		messagedVendors,
		size = 'lg',
		styleName,
		type = TYPES.REQUEST,
		vendor,
		vendorRaw,
		wrapStyle = Styles.oneButton,
		htmlId,
	} = props;

	if (!vendorHasPrimaryEmail(vendorRaw)) {
		return <div />;
	}
	const buttonId =
		color === 'secondary' ? 'message-button' : htmlId ? htmlId : 'rfq-button';

	if (label === 'Message Vendor' && messagedVendors[vendor.id]) {
		return null;
	}

	return messagedVendors[vendor.id] ? (
		<Link
			id="conversation-link"
			className={cx(
				determineButtonStyle(vendor, vendorRaw, wrapStyle),
				Styles.inboxLink,
				styleName,
			)}
			color={color}
			href={`${inboxUrl}/${messagedVendors[vendor.id]}`}
			size={size}
		>
			View Conversation
		</Link>
	) : (
		<Button
			id={buttonId}
			color={color}
			className={styleName}
			onClick={handleCTAClick}
			size={size}
		>
			{getCategoryLabel(vendorRaw, type, label)}
		</Button>
	);
});

function mapStateToProps(state: State) {
	return {
		messagedVendors: state.messagedVendors.conversations,
		vendor: state.vendor.vendor,
		vendorRaw: state.vendor.vendorRaw,
	};
}

export default connect(mapStateToProps)(RfqButton);
