import constants from '@containers/widget/constants';
import type { ErrorMessage } from '@typings/api';
import { PvRecAnonTemplate } from '@typings/api';
import type { Raw as VendorRaw } from '@typings/vendor';
import type {
	FetchBowVendors,
	FetchBowVendorsError,
	FetchBowVendorsSuccess,
	FetchPvRecAnonError,
	FetchPvRecAnonSuccess,
} from '@typings/widget/actionTypes';

export const fetchPvRecAnonSuccess = (
	response: PvRecAnonTemplate,
): FetchPvRecAnonSuccess => ({
	type: constants.FETCH_PVREC_ANON_VENDORS_SUCCESS,
	payload: response,
});

export const fetchPvRecAnonError = (
	response: ErrorMessage,
): FetchPvRecAnonError => ({
	type: constants.FETCH_PVREC_ANON_VENDORS_ERROR,
	payload: response,
});

export const fetchBowVendorsAction = (): FetchBowVendors => ({
	type: constants.FETCH_BOW_VENDORS,
});

export const fetchBowVendorsSuccessAction = (
	response: VendorRaw[],
): FetchBowVendorsSuccess => ({
	type: constants.FETCH_BOW_VENDORS_SUCCESS,
	payload: response,
});

export const fetchBowVendorsErrorAction = (
	error: ErrorMessage,
): FetchBowVendorsError => ({
	type: constants.FETCH_BOW_VENDORS_ERROR,
	payload: error,
});
