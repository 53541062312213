import { Button } from '@xo-union/tk-component-buttons';
import React, { VFC, memo } from 'react';
import { connect } from 'react-redux';
import { checkIsTablet, determineButtonText } from '../../utils';
import Styles from './styles.scss';

export interface SubmitCTAProps {
	code: Category.CategoryCode;
	handleSubmit: () => void;
	isMobile: boolean;
	viewport: Redux.Viewport;
}

export const SubmitCTA: VFC<SubmitCTAProps> = memo((props) => {
	const { code, handleSubmit, isMobile, viewport } = props;
	const isTablet = checkIsTablet(viewport);

	return (
		<div className={Styles.submitCta}>
			<Button color="primary" onClick={handleSubmit} size="lg">
				{determineButtonText({ code, isMobile, isTablet })}
			</Button>
		</div>
	);
});

const mapStateToProps = (state: Redux.State) => ({
	code: state.category.code,
	isMobile: state.viewport.isMobile,
	viewport: state.viewport,
});

export default connect(mapStateToProps)(SubmitCTA);
