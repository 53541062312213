import { inboxUrl } from '@settings';
import { Body1, Subhead } from '@xo-union/tk-ui-typography';
import React, { VFC } from 'react';
import { connect } from 'react-redux';
import ViewConversationLink from '../ViewConversationLink';
import Styles from './styles.scss';
import { buildCreationDate, buildUrl } from './utils';

interface ViewConversationProps {
	conversations: MessagedVendors.Conversations;
	timestamps: MessagedVendors.Timestamps[];
	vendorId: Vendor.Raw['id'];
	vendorName: Vendor.Raw['name'];
}

const ViewConversation: VFC<ViewConversationProps> = (props) => {
	const { conversations, timestamps, vendorId, vendorName } = props;

	return (
		<section className={Styles.container}>
			<Subhead bold className={Styles.title}>
				Message Vendor
			</Subhead>
			<Body1 className={Styles.subTitle}>
				You messaged {vendorName}
				{buildCreationDate({ timestamps, vendorId })}.
			</Body1>
			<ViewConversationLink
				isBlock
				href={buildUrl({ conversations, inboxUrl, vendorId })}
			/>
		</section>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	conversations: state.messagedVendors.conversations,
	timestamps: state.messagedVendors.timestamps,
	vendorId: state.vendor.vendorRaw?.id,
	vendorName: state.vendor.vendorRaw?.name,
});

export default connect(mapStateToProps)(ViewConversation);
