import { Link } from '@xo-union/tk-component-buttons';
import React, { VFC } from 'react';

export interface ViewConversationLinkProps {
	href: string;
	isBlock: boolean;
}

export const ViewConversationLink: VFC<ViewConversationLinkProps> = (props) => {
	const { href, isBlock } = props;

	return (
		<Link block={isBlock || undefined} color="secondary" href={href} size="lg">
			View Conversation
		</Link>
	);
};

export default ViewConversationLink;
