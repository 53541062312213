import { selectAssignment } from '@redux/experiments/selectors/index';
import { LiteRFQVendorWidgetAssignment } from 'types/experiments';
import { State } from 'types/redux';

export const liteRFQVendorWidgetAssignmentSelector = (state: State) => {
	// Only on storefronts and not for Photographers on Desktop
	const isPhotographerOnDesktop =
		state.vendor.vendor?.categoryCode === 'WPH' &&
		state.viewport.isMobile === false;
	if (isPhotographerOnDesktop) return null;
	if (state.page.pageType !== 'storefront') return null;

	return selectAssignment(state, [
		'liteRFQVendorWidget',
	]) as LiteRFQVendorWidgetAssignment;
};

export const isLiteRFQVendorWidgetSelector = (state: State) => {
	const assignment = liteRFQVendorWidgetAssignmentSelector(state);
	return assignment === 'variant1';
};
