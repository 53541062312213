import { StatusBadge } from '@xo-union/tk-component-badges';
import { Img } from '@xo-union/tk-component-picture';
import { H3 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React from 'react';
import { Decorated } from 'types/vendor';
import Styles from './TeamInfo.scss';

interface VendorInfoProps {
	bio: Decorated['bio'];
	isInLiteRFQ?: boolean;
	includeQuickResponderBadge?: boolean;
}
interface TeamInfoProps {
	bio: Decorated['bio'];
}

interface AvatarProps {
	url: string;
	size?: number;
}

const Avatar = ({ url, size }: AvatarProps) => {
	return (
		<Img
			src={`${url}~sc_${size}.${size}`}
			className={Styles.img}
			alt="meet the owner"
		/>
	);
};

export const VendorInfo = ({
	bio,
	isInLiteRFQ = false,
	includeQuickResponderBadge = false,
}: VendorInfoProps) => {
	const { name, displayRole, photo } = bio || {};

	if (!name && !displayRole) {
		return null;
	}

	return (
		<div
			className={classNames(Styles.vendorInfoWrapper, {
				[Styles.isInLiteRFQ]: isInLiteRFQ,
			})}
		>
			{photo?.url && <Avatar url={photo.url} size={90} />}
			<div className={Styles.intro}>
				<div className={Styles.name}>{name}</div>
				<div className={Styles.displayRole}>{displayRole}</div>
				{includeQuickResponderBadge && (
					<StatusBadge size="sm" type="informational">
						Typically responds within 24 hours
					</StatusBadge>
				)}
			</div>
		</div>
	);
};

const TeamInfo = ({ bio }: TeamInfoProps) => {
	const { description } = bio || {};

	return (
		<div>
			<H3 className={Styles.title}>Meet the team</H3>
			<VendorInfo bio={bio} />
			<div className={Styles.description}>{description}</div>
		</div>
	);
};

export default TeamInfo;
